import React, { FC, PropsWithChildren } from "react";
import { useSelector } from "react-redux";

import { hasSubmitSucceeded } from "core/form";
import { ChangeSuccessTextType } from "types";

import { InPageFormHeader } from "./InPageFormHeader";
import { InPageSuccess } from "./InPageSuccess";
import { InPageFormErrorBoundary } from "./InPageFormErrorBoundary";

export interface InPageFormLayoutProps extends PropsWithChildren {
    formName: string;
    formTitle: string;
    formLead?: string;
    changeSuccessTextType: ChangeSuccessTextType;
    showFeedbackSurvey?: boolean;
}

export const InPageFormLayout: FC<InPageFormLayoutProps> = ({
    children,
    formName,
    formTitle,
    formLead,
    changeSuccessTextType,
    showFeedbackSurvey,
}) => {
    const isFormSubmitted = useSelector(hasSubmitSucceeded(formName));

    return (
        <>
            <InPageFormHeader title={formTitle} lead={formLead} />
            <InPageFormErrorBoundary service={formName}>
                {isFormSubmitted ? (
                    <InPageSuccess changeSuccessTextType={changeSuccessTextType} showFeedbackSurvey={showFeedbackSurvey} />
                ) : (
                    children
                )}
            </InPageFormErrorBoundary>
        </>
    );
};
