import React, { FC } from "react";

import { Box } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

import i18n, { TFunction } from "core/i18n";
import router from "core/router";
import { Pages, Tabs } from "routeConstants";
import { ChangeSuccessTextType } from "types";
import { InPageFormButtonsLayout } from "./InPageFormButtonsLayout";
import { CancelOrCloseRequestButton } from "./CancelOrCloseRequestButton";
import { SurveyForm } from "./SurveyForm";

const getMainText = (t: TFunction, changeSuccessTextType: ChangeSuccessTextType): string => {
    switch (changeSuccessTextType) {
        case ChangeSuccessTextType.CHANGE_PROCESSED:
            return t("success.contractName.title.processed");
        default:
            return t("success.title.sent");
    }
};

const getSecondaryText = (t: TFunction, changeSuccessTextType: ChangeSuccessTextType, changeRequestsLink?: string): string => {
    switch (changeSuccessTextType) {
        case ChangeSuccessTextType.CHANGE_SUBMITTED_FOR_PROCESSING:
            return t("success.text", { link: changeRequestsLink });
        default:
            return null;
    }
};

type InPageSuccessProps = {
    changeSuccessTextType: ChangeSuccessTextType;
    showFeedbackSurvey?: boolean;
};

export const InPageSuccess: FC<InPageSuccessProps> = ({ changeSuccessTextType, showFeedbackSurvey }) => {
    const { t } = i18n.useTranslation();
    const changeRequestsLink = router.getStaticUrl(Pages.MESSAGES, Tabs.REQUESTS);

    const mainText = getMainText(t, changeSuccessTextType);
    const secondaryText = getSecondaryText(t, changeSuccessTextType, changeRequestsLink);

    return (
        <>
            <Box mt={4} mb={3} fullWidth textAlign={"center"}>
                <Typography variant="h4" markDown={mainText} />
            </Box>
            <Box mb={3} fullWidth textAlign={"center"}>
                {secondaryText && <Typography markDown={secondaryText} internalAppLink />}
            </Box>

            {showFeedbackSurvey && <SurveyForm />}

            <InPageFormButtonsLayout rightButton={<CancelOrCloseRequestButton label={t("common.close")} />} />
        </>
    );
};
