import React from "react";
import { useParams } from "react-router-dom";
import { Button, Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import i18n from "core/i18n";
import router from "core/router";
import { Pages, Tabs } from "routeConstants";
import { SurveyForm } from "containers/inPageForm/SurveyForm";

export default function StepConfirmation() {
    const { t } = i18n.useTranslation();
    const { idObject } = useParams();

    function onClose() {
        const url = router.getStaticUrl(Pages.CONTRACT_VEHICLE, Tabs.CHANGES, { idObject });
        window.location.href = url;
    }

    return (
        <Box fullWidth textAlign={"center"}>
            <Box mb={6}>
                <Typography variant="h3">{t("vehicle.change.subject.confirmation.title")}</Typography>
            </Box>

            <SurveyForm />

            <Button variant="contained" color="blue" onClick={onClose}>
                {t("common.close")}
            </Button>
        </Box>
    );
}
