import React, { FC, PropsWithChildren } from "react";
import { useSelector } from "react-redux";

import { isOtpFormSuccessfullySubmitted } from "core/form";
import { FullPageErrorBoundary } from "core/errorHandling";

import { FullPageFormHeader } from "./FullPageFormHeader";
import { FullPageSuccess } from "./FullPageSuccess";
import { FullPageEditFormError } from "./FullPageEditFormError";

export interface FullPageOtpFormLayoutProps extends PropsWithChildren {
    formName: string;
    formTitle: string;
    formLead?: string;
    BackButton?: any;
    SuccessComponent?: any;
    showFeedbackSurvey?: boolean;
    surveyFormJourney?: "Change" | "Maturity";
}

export const FullPageOtpFormLayout: FC<FullPageOtpFormLayoutProps> = ({
    children,
    formName,
    formTitle,
    formLead,
    BackButton,
    showFeedbackSurvey,
    surveyFormJourney = "Change",
    SuccessComponent = FullPageSuccess,
}) => {
    const isFormSubmitted = useSelector(isOtpFormSuccessfullySubmitted(formName));

    return (
        <>
            <FullPageFormHeader title={formTitle} lead={formLead} />
            <FullPageErrorBoundary service={formName} ErrorComponent={FullPageEditFormError} BackButton={BackButton}>
                {isFormSubmitted ? (
                    <SuccessComponent
                        BackButton={BackButton}
                        showFeedbackSurvey={showFeedbackSurvey}
                        surveyFormJourney={surveyFormJourney}
                    />
                ) : (
                    children
                )}
            </FullPageErrorBoundary>
        </>
    );
};
