import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Button, Container, Loading } from "ui-library/atoms";
import { Flexbox } from "ui-library/layouts";
import { FormBlock } from "ui-library/modules";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n from "core/i18n";
import { OtpWrongNumberDss } from "containers/error";
import { FullPageOtpFormLayout } from "containers/fullPageForm";
import errorHandling, { OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS } from "core/errorHandling";
import { form, FormErrorBox } from "core/form";
import { date } from "core/util";
import { PensionDssFundsChangeType } from "enums";
import { PensionFundsChangePermittedByType } from "model/pension";

import { BackToFinancialStatementButton, DssFundsChangeComponent, FundsChangeDssNotPermittedComponent } from "./components";
import { PisDisclaimerFormBlock } from "./components/textBlock";

const IS_YES = "Y";
const PIS_INACTIVE = "0";
const CHANGE_LAW_DATE = "2023-01-01";

const DssContainer = ({
    canSubmit,
    handleSubmit,
    formName,
    submitting,
    actualStrategies,
    OtpModule,
    fundsChangePermittedEntity,
    idObject,
    changeType,
    slowServices,
    pensionAccountDetail,
}) => {
    const DssErrorComponent = () => <OtpWrongNumberDss idObject={idObject} />;
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const onOtpBack = () => dispatch(OtpModule.clear(formName));

    const otpWrongNumberErrorExists = useSelector(errorHandling.isServiceInErrorState(OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS));
    const isFetchingAnySlow = useSelector(fetching.isFetchingAny(slowServices));
    const isFundsChangePermittedLoading = useSelector(fetching.isFetching(fundsChangePermittedEntity, true));

    const isOtpVisible = useSelector(OtpModule.createIsVisibleSelector(formName));
    const fundsChangePermittedObj = useSelector(entity.getData(fundsChangePermittedEntity, PensionFundsChangePermittedByType.fromServer()));
    const isTransferFundsDataPossible = fundsChangePermittedObj.transferFundsDataPossible === IS_YES;
    const isChangeStrategyDataPossible = fundsChangePermittedObj.changeStrategyDataPossible === IS_YES;
    const isGuaranteedFundRatioReductionPossible = fundsChangePermittedObj.isGuaranteedFundRatioReductionPossible === IS_YES;
    const isGuaranteedFundRatioReductionInitiallySet = pensionAccountDetail.isGuaranteedFundRatioReductionSet;
    const isAnyChangePossible = isChangeStrategyDataPossible || isTransferFundsDataPossible || isGuaranteedFundRatioReductionPossible;
    const smsVerificationStepNumber = changeType === PensionDssFundsChangeType.VOLUNTARY.id ? 3 : 4;
    const isPisActive = pensionAccountDetail?.pis?.code !== PIS_INACTIVE;
    const isOldContract = date.isBefore(pensionAccountDetail.signDate, CHANGE_LAW_DATE);

    return (
        <Container maxWidth="md">
            <Loading fullPage loading={submitting || isFetchingAnySlow || isFundsChangePermittedLoading} />

            {/* PIS disclaimer. */}
            {isAnyChangePossible && isPisActive && <PisDisclaimerFormBlock />}

            {/* Errors box */}
            <FormErrorBox form={formName} />

            {/* Main form. */}
            <FormBlock>
                {isAnyChangePossible ? (
                    <DssFundsChangeComponent
                        formName={formName}
                        isOtpVisible={isOtpVisible}
                        changeType={changeType}
                        actualStrategies={actualStrategies}
                        isOldContract={isOldContract}
                        isTransferFundsDataPossible={isTransferFundsDataPossible}
                        isChangeStrategyDataPossible={isChangeStrategyDataPossible}
                        isGuaranteedFundRatioReductionPossible={isGuaranteedFundRatioReductionPossible}
                        isGuaranteedFundRatioReductionInitiallySet={isGuaranteedFundRatioReductionInitiallySet}
                    />
                ) : (
                    <FundsChangeDssNotPermittedComponent errorMessage={fundsChangePermittedObj.error} />
                )}

                {isOtpVisible && (
                    <OtpModule.Container formName={formName} stepNumber={smsVerificationStepNumber} ErrorComponent={DssErrorComponent} />
                )}

                <Flexbox gap={2} justifyContent="space-between">
                    {/* Back Button */}
                    {isOtpVisible && !otpWrongNumberErrorExists ? (
                        <Button onClick={onOtpBack} variant="outlined" color="blue">
                            {t("form.otp.backToFirstStep")}
                        </Button>
                    ) : (
                        <BackToFinancialStatementButton idObject={idObject} />
                    )}

                    {/* Submit Button */}
                    <Button onClick={handleSubmit} variant="contained" color="blue" disabled={!canSubmit || otpWrongNumberErrorExists}>
                        {t("common.send")}
                    </Button>
                </Flexbox>
            </FormBlock>
        </Container>
    );
};

DssContainer.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    formName: PropTypes.string.isRequired,
    submitting: PropTypes.bool.isRequired,
    idObject: PropTypes.number.isRequired,
    OtpModule: PropTypes.object.isRequired,
    slowServices: PropTypes.any.isRequired,
    fundsChangePermittedEntity: PropTypes.string.isRequired,
    actualStrategies: PropTypes.object.isRequired,
};

export const createContainerDss = (
    formName,
    idObjectSelector,
    fundsChangeFormChangeTypeSelector,
    pensionAccountDetailSelector,
    actualFundsStrategiesByTypeSelector,
    OtpModule,
    slowServices,
    fundsChangePermittedEntity,
) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        const idObject = useSelector(idObjectSelector);
        const changeType = useSelector(fundsChangeFormChangeTypeSelector);
        const pensionAccountDetail = useSelector(pensionAccountDetailSelector);
        const actualStrategies = useSelector(actualFundsStrategiesByTypeSelector(changeType));

        return (
            <FullPageOtpFormLayout
                formName={formName}
                formTitle={t("pension.fundsChange.title")}
                BackButton={<BackToFinancialStatementButton idObject={idObject} />}
                showFeedbackSurvey
            >
                <DssContainer
                    {...props}
                    formName={formName}
                    idObject={idObject}
                    changeType={changeType}
                    actualStrategies={actualStrategies}
                    OtpModule={OtpModule}
                    slowServices={slowServices}
                    fundsChangePermittedEntity={fundsChangePermittedEntity}
                    pensionAccountDetail={pensionAccountDetail}
                />
            </FullPageOtpFormLayout>
        );
    });
