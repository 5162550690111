import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import { withTheme } from "@mui/styles";

export const StyledRating = withTheme(
    styled(Rating)({
        "& .MuiRating-iconFilled": {
            color: "#4C92CF",
        },
        "& .MuiRating-iconEmpty": {
            color: "#4C92CF",
        },
        "& .MuiRating-iconHover": {
            color: "#005CA9",
        },
    }),
);
