import React, { FC } from "react";
import { useSelector } from "react-redux";

import { Radio, Typography } from "ui-library/atoms";
import { ContentBox, GridItem, GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";
import pageContext from "core/pageContext";
import {
    DatePicker,
    Field,
    FormWideDisabledProvider,
    getFormFieldValue,
    InputText,
    lettersNumbersBasicInterpunctionCharacters,
    max50Characters,
    minTwoLetters,
    RadioSelect,
    required,
} from "core/form";
import { FeatureFlag, SignatureType } from "types";

import { FIELD_DATE_OF_SIGNING, FIELD_PLACE_OF_SIGNING, FIELD_SIGNATURE_TYPE } from "../../constants";

type SignatureSectionProps = {
    formName: string;
    isDisabled?: boolean;
};

export const SignatureSection: FC<SignatureSectionProps> = ({ formName, isDisabled = false }) => {
    const { t } = i18n.useTranslation();
    const selectedSignatureType = useSelector(getFormFieldValue(formName, FIELD_SIGNATURE_TYPE));
    const isDigisignEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.ENABLE_DIGISIGN);

    return (
        <>
            <ContentBox fullWidth textAlign={"center"}>
                <Typography variant="h3">{isDigisignEnabled ? t("change.signature.type.title") : t("change.signature.title")}</Typography>
            </ContentBox>

            <FormWideDisabledProvider value={isDisabled}>
                {/* Signature type */}
                {isDigisignEnabled && (
                    <ContentBox fullWidth>
                        <Field component={RadioSelect} name={FIELD_SIGNATURE_TYPE} direction="row" validateStatic={[required]}>
                            <Radio id={SignatureType.PHYSICAL} value={SignatureType.PHYSICAL} label={t("change.signature.type.physical")} />
                            <Radio
                                id={SignatureType.ELECTRONIC}
                                value={SignatureType.ELECTRONIC}
                                label={t("change.signature.type.digital")}
                            />
                        </Field>
                    </ContentBox>
                )}

                {selectedSignatureType === SignatureType.PHYSICAL && (
                    <ContentBox fullWidth>
                        <GridLayout spacing={2}>
                            <GridItem xs={12} md={6}>
                                <Field
                                    name={FIELD_DATE_OF_SIGNING}
                                    component={DatePicker}
                                    label={t("change.signature.date")}
                                    validateStatic={[required]}
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <Field
                                    name={FIELD_PLACE_OF_SIGNING}
                                    component={InputText}
                                    label={t("change.signature.place")}
                                    validateStatic={[required, minTwoLetters]}
                                    normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                                />
                            </GridItem>
                        </GridLayout>
                    </ContentBox>
                )}
            </FormWideDisabledProvider>
        </>
    );
};
