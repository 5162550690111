import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import i18n from "core/i18n";
import { Field, Form } from "core/hookForm";
import userContracts from "core/userContracts";
import surveyApi from "serverApi/survey";
import { SubmitSurveyRequest } from "types";
import { Box, Flexbox } from "ui-library/layouts";
import { Button, ImportantAnnouncements, Loading, Typography } from "ui-library/atoms";

type SurveyFormProps = {
    journey?: "Change" | "Maturity";
};

type SurveyFormValues = {
    stars?: number;
    comment?: string;
};

export const SurveyForm: FC<SurveyFormProps> = ({ journey = "Change" }) => {
    const { t } = i18n.useTranslation();
    const contract = userContracts.useGetContractByUrlParam();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const initialValues: SurveyFormValues = {
        stars: 0,
        comment: "",
    };

    const form = useForm<SurveyFormValues>({
        defaultValues: initialValues,
    });

    const onSubmit = async (data: SurveyFormValues) => {
        const request: SubmitSurveyRequest = {
            comment: data.comment,
            stars: data.stars,
            contractNumber: contract?.contractNumber,
            journey,
        };
        setIsSubmitting(true);

        const resp = surveyApi.submit(request);
        resp.then((response) => {
            setIsSubmitting(false);
            setIsSubmitted(true);
        });
    };

    if (isSubmitted) {
        return (
            <Box mb={3} textAlign="left">
                <ImportantAnnouncements
                    shadow
                    variant={"SUCCESS"}
                    data={{
                        heading: t("survey.submitted"),
                        content: t("survey.thankyou"),
                    }}
                />
            </Box>
        );
    }

    return (
        <Form form={form} onSubmit={onSubmit}>
            <Flexbox
                textAlign="left"
                direction="column"
                justifyContent={"center"}
                justifyItems={"center"}
                mt={2}
                p={4}
                sx={{ backgroundColor: "#eef2fa" }}
            >
                <Box mb={2}>
                    <Typography variant="p">{t("survey.title")}</Typography>
                </Box>
                <Box mb={2}>
                    <Field name="stars" input="rating" label={t("survey.stars.label")} />
                </Box>
                {!!form.getValues("stars") && (
                    <Box mb={2}>
                        <Field
                            name="comment"
                            input="textField"
                            label={t("survey.comment.label")}
                            placeholder={
                                Number(form.getValues("stars")) > 3 ? t("survey.comment.placeholder45") : t("survey.comment.placeholder123")
                            }
                        />
                    </Box>
                )}
                <Box>
                    <Button
                        variant="contained"
                        color="blue"
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={!form.getValues("stars") || isSubmitting}
                    >
                        {isSubmitting ? <Loading loading={true} /> : t("survey.submit")}
                    </Button>
                </Box>
            </Flexbox>
        </Form>
    );
};
