import React, { forwardRef } from "react";
import { useFormContext } from "react-hook-form";
import { Rating as NoRefRatingField } from "ui-library/atoms/Inputs/Rating";
import { Box, Flexbox } from "ui-library/layouts";
import { ErrorContent, Label } from "ui-library/atoms";

type HookFormTextFieldProps = {
    name: string;
    required?: boolean;
    label?: string;
};

const RatingField = forwardRef((props: any, ref) => <NoRefRatingField {...props} /*reference={ref}*/ />);

export default function HookFormRating({ name, required, label, ...props }: HookFormTextFieldProps) {
    const {
        register,
        setValue,
        getValues,
        formState: { errors },
    } = useFormContext();

    const error = name.split(".").reduce((acc, part) => acc?.[part], errors);

    function onChange(event: any) {
        const { value } = event.target;
        setValue(name, value, { shouldDirty: true, shouldValidate: true });
    }

    return (
        <>
            <Flexbox alignItems={"flex-start"} gap={1} mb={2}>
                <Label label={label} id={name} />
            </Flexbox>

            <RatingField id={name} {...register(name)} {...props} value={getValues(name)} onChange={onChange} size="large" />

            {Boolean(error) ? <ErrorContent content={error?.message.toString()} /> : ""}
        </>
    );
}
