import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nanoid, Selector } from "@reduxjs/toolkit";
import { arrayPush } from "redux-form/immutable";
import { List, Map } from "immutable";

import i18n from "core/i18n";
import router from "core/router";
import { FullPageFormLayout } from "containers/fullPageForm";
import { form, FormWideDisabledProvider, getFormFieldValue } from "core/form";
import { Pages, Tabs } from "routeConstants";
import { ContentBox, Flexbox, Section } from "ui-library/layouts";
import { Accordion, AccordionTitle, Button, Container, Loading, Typography } from "ui-library/atoms";
import { FormBlock } from "ui-library/modules";
import { ChangeSuccessTextType, PensionBeneficiary, State } from "types";

import { deleteBeneficiary } from "./actions";
import { BackToPolicyDetailButton } from "./components";
import { BENEFICIARIES_FIELD, MAX_BENEFICIARIES } from "./constants";
import { BeneficiaryDps } from "./containers";
import { InPageFormButtonsLayout, InPageFormLayout } from "../../../containers/inPageForm";
import userContracts from "../../../core/userContracts";
import { useParams } from "react-router-dom";

const createEmptyBeneficiary = () =>
    Map({
        reactKey: nanoid(),
        firstName: "",
        lastName: "",
        birthNumber: "",
        share: "",
        address: Map({ state: "CZ" }),
    });

type ContainerDpsType = {
    formName: string;
    beneficiariesSelector: Selector<State, PensionBeneficiary[]>;
    idObject: number;
    canSubmit: boolean;
    handleSubmit: () => void;
    submitting: boolean;
};

const ContainerDps: FC<ContainerDpsType> = ({ formName, beneficiariesSelector, idObject, canSubmit, handleSubmit, submitting }) => {
    const { t } = i18n.useTranslation();

    const beneficiaries = useSelector(beneficiariesSelector);
    const originalBeneficiariesCount = beneficiaries ? beneficiaries.length : 0;
    const formBeneficiaries = useSelector(getFormFieldValue(formName, BENEFICIARIES_FIELD)) || List();

    const maxBeneficiaryCount = originalBeneficiariesCount > MAX_BENEFICIARIES ? originalBeneficiariesCount : MAX_BENEFICIARIES;
    const formBeneficiaryCount = formBeneficiaries ? formBeneficiaries.size : 0;
    const showRemove = originalBeneficiariesCount > 0 || formBeneficiaryCount > 1;

    const data = formBeneficiaries
        .map((beneficiary, index) => {
            const beneficiaryName = `${beneficiary.get("firstName")} ${beneficiary.get("lastName")}`;
            return {
                title: (
                    <AccordionTitle
                        text={beneficiaryName.trim() ? beneficiaryName : `${t("holder.firstName")} ${t("holder.lastName")}`}
                        shareNumber={beneficiary.get("share") ? `${beneficiary.get("share")} %` : "0 %"}
                    />
                ),
                children: <BeneficiaryDps index={index} />,
            };
        })
        .toArray();

    const dispatch = useDispatch();
    const addNewBeneficiary = () => dispatch(arrayPush(formName, BENEFICIARIES_FIELD, createEmptyBeneficiary()));
    const removeBeneficiary = (index) => dispatch(deleteBeneficiary(index));

    const agentTabLink = router.getStaticUrl(Pages.CONTRACT_DPS, Tabs.AGENT, { idObject });
    const foreignerBeneficiaryText = i18n.translateDirectly("pension.dps.foreignerBeneficiaryText", { agentTabLink });

    return (
        <Container maxWidth="md">
            <FormWideDisabledProvider value={submitting}>
                <ContentBox>
                    <Typography markDown={t("pension.dps.editBeneficiaryText")} />
                </ContentBox>

                <ContentBox mb={4}>
                    {/* @ts-ignore TODO: martin.rodin fix types in i18n later */}
                    <Typography markDown={foreignerBeneficiaryText} internalAppLink />
                </ContentBox>

                <Section>
                    <Accordion
                        defaultLastExpanded
                        compact
                        deleteAction={showRemove ? removeBeneficiary : false}
                        data={data}
                        id="baseAccordion"
                    />
                </Section>

                {formBeneficiaryCount < maxBeneficiaryCount && (
                    <Section>
                        <Button variant="outlined" startIcon="plus" color="blue" onClick={addNewBeneficiary}>
                            {t("pension.addBeneficiary")}
                        </Button>
                    </Section>
                )}

                <ContentBox mb={4}>
                    <Typography variant="caption">
                        {formBeneficiaryCount === 0 ? t("pension.ppDps.noBeneficiaries") : t("pension.dps.editBeneficiaryAcceptanceText")}
                    </Typography>
                </ContentBox>
            </FormWideDisabledProvider>

            <InPageFormButtonsLayout
                rightButton={
                    <Button variant="contained" color="blue" onClick={handleSubmit} disabled={!canSubmit || submitting}>
                        {submitting ? <Loading loading /> : t("common.send")}
                    </Button>
                }
            />
        </Container>
    );
};

export const createInPageContainerDps = (formName: string, beneficiariesSelector: Selector<State, PensionBeneficiary[]>) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        const { idObject } = useParams();
        const contract = userContracts.useGetContractById(idObject);

        return (
            <InPageFormLayout
                formName={formName}
                formTitle={t("change.title.BENEFICIARIES", { contractNumber: contract.contractNumber })}
                formLead={t("form.editPensionBeneficiaries.lead")}
                changeSuccessTextType={ChangeSuccessTextType.CHANGE_SUBMITTED_FOR_PROCESSING}
                showFeedbackSurvey
            >
                <ContainerDps {...props} formName={formName} beneficiariesSelector={beneficiariesSelector} idObject={idObject} />
            </InPageFormLayout>
        );
    });
