import React, { FC } from "react";
import { Box, GridItem, GridLayout } from "ui-library/layouts";
import { InfoPanel, LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import { localPhone } from "core/formatters";
import { getLocalizedOneLineAddressFull } from "core/localized";
import { model } from "core/util";
import { AddressTypeCodeEnum, ElectronicCommunication, Holder } from "types";

import { HolderPersonSection } from "./HolderPersonSection";
import { HolderCompanySection } from "./HolderCompanySection";

type ContractPolicyHolderTabProps = {
    holder: Holder;
    isExtended?: boolean;
    showInsuranceNumber?: boolean;
    electronicCommunication?: ElectronicCommunication;
};

export const ContractPolicyHolderTab: FC<ContractPolicyHolderTabProps> = ({
    holder,
    isExtended = false,
    showInsuranceNumber = true,
    electronicCommunication = null,
}) => {
    const { t } = i18n.useTranslation();

    return (
        <GridLayout defaultSpacing>
            <GridItem xs={12}>
                <InfoPanel icon="user" title={t("common.personalData")}>
                    {holder.isPerson ? (
                        <HolderPersonSection holder={holder} isExtended={isExtended} showInsuranceNumber={showInsuranceNumber} />
                    ) : (
                        <HolderCompanySection holder={holder} />
                    )}
                </InfoPanel>
            </GridItem>
            <GridItem xs={12}>
                <InfoPanel icon="messages" title={t("common.contact")}>
                    <Box>
                        <LabelTextItem label={t("holder.phone")} text={localPhone(holder.phone)} />
                        <LabelTextItem label={t("holder.email")} text={holder.email} />
                        <LabelTextItem
                            label={t("holder.contactAddress")}
                            text={getLocalizedOneLineAddressFull(t, model.getAddressByTypeCode(holder, AddressTypeCodeEnum.CON))}
                        />
                        <LabelTextItem
                            label={t("common.eCommunication")}
                            text={electronicCommunication?.electronicCommunicationSet ? t("common.yes") : t("common.no")}
                        />
                    </Box>
                </InfoPanel>
            </GridItem>
        </GridLayout>
    );
};
