import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { ContentBox, GridItem, GridLayout } from "ui-library/layouts";
import { Button, Loading, Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import localized from "core/localized";
import pageContext from "core/pageContext";
import { PAYMENT_CONTRIBUTIONS_OPTIONS_EX_AXA, PAYMENT_CONTRIBUTIONS_OPTIONS_UNIQA } from "app/constants";
import {
    amountCharacters,
    Field,
    form,
    greaterThanZero,
    InputText,
    max10Characters,
    numberCharacters,
    required,
    SingleSelect,
    validAmount,
    FormWideDisabledProvider,
} from "core/form";
import { localCurrency } from "core/formatters";
import { InPageFormButtonsLayout, InPageFormLayout } from "containers/inPageForm";
import { Countries } from "enums";
import { useParams } from "react-router-dom";
import userContracts from "core/userContracts";
import { ChangeSuccessTextType } from "types";

const getAmountNormalizerBySite = (siteId) => (siteId === Countries.SK.id ? amountCharacters : numberCharacters);
const getValidateStaticBySite = (siteId) =>
    siteId === Countries.SK.id ? [required, validAmount, greaterThanZero] : [greaterThanZero, required];

const ContractOwnerPaymentsForm = ({
    canSubmit,
    handleSubmit,
    submitting,
    canEditAmount,
    showFrequency,
    showPaymentType,
    isUniqaContract,
    hasMonthlyFrequency,
    amountLabel,
    paymentOptionsUniqa,
    paymentOptionsUniqaExAxa,
    displayDisclaimer,
}) => {
    const { t } = i18n.useTranslation();
    const { siteId, currencyCode } = pageContext.useGetPageLocalization();

    const amountLabelMsg = amountLabel ? amountLabel : t("payments.amount");

    const paymentOptionsMonthIncluded = useSelector(localized.getLocalizedFrequencyOptions);
    const paymentOptionsMonthExcluded = useSelector(localized.getLocalizedFrequencyNonLifeOptions);
    const frequencyOptions = hasMonthlyFrequency ? paymentOptionsMonthIncluded : paymentOptionsMonthExcluded;

    const paymentContributionOptions = useSelector(localized.getLocalizedPaymentContributionOptions);
    const paymentOptions = isUniqaContract ? paymentOptionsUniqa : paymentOptionsUniqaExAxa;

    return (
        <>
            <FormWideDisabledProvider value={submitting}>
                <ContentBox fullWidth>
                    <GridLayout defaultSpacing>
                        <GridItem xs={12} md={6}>
                            <Field
                                name="amount"
                                component={InputText}
                                label={amountLabelMsg}
                                sufix={localCurrency(currencyCode)}
                                validateStatic={getValidateStaticBySite(siteId)}
                                normalize={canEditAmount ? [getAmountNormalizerBySite(siteId), max10Characters] : []}
                                disabled={!canEditAmount}
                                onEnter={handleSubmit}
                            />
                        </GridItem>
                    </GridLayout>
                </ContentBox>

                {showFrequency && (
                    <ContentBox fullWidth>
                        <GridLayout defaultSpacing>
                            <GridItem xs={12} md={6}>
                                <Field
                                    name="frequencyOfPayment"
                                    component={SingleSelect}
                                    label={t("payments.frequency")}
                                    options={frequencyOptions}
                                    validateStatic={[required]}
                                    placeholder={t("common.choose")}
                                />
                            </GridItem>
                        </GridLayout>
                    </ContentBox>
                )}

                {showPaymentType && (
                    <ContentBox fullWidth>
                        <GridLayout defaultSpacing>
                            <GridItem xs={12} md={6}>
                                <Field
                                    name="paymentContribution"
                                    component={SingleSelect}
                                    label={t("payments.type")}
                                    options={paymentContributionOptions.filter((item) => paymentOptions.includes(item.get("value")))}
                                    validateStatic={[required]}
                                    placeholder={t("common.choose")}
                                />
                            </GridItem>
                        </GridLayout>
                    </ContentBox>
                )}
            </FormWideDisabledProvider>

            {displayDisclaimer && (
                <ContentBox fullWidth>
                    <Typography variant="caption" markDown={t("insurance.text.contractOwnerPayments")} />
                </ContentBox>
            )}

            <InPageFormButtonsLayout
                rightButton={
                    <Button variant="contained" color="blue" onClick={handleSubmit} disabled={!canSubmit || submitting}>
                        {submitting ? <Loading loading /> : t("common.submitChange")}
                    </Button>
                }
            />
        </>
    );
};

ContractOwnerPaymentsForm.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    canEditAmount: PropTypes.bool,
    showFrequency: PropTypes.bool,
    showPaymentType: PropTypes.bool,
    isUniqaContract: PropTypes.bool,
    hasMonthlyFrequency: PropTypes.bool,
    amountLabel: PropTypes.string,
    paymentOptionsUniqa: PropTypes.object,
    paymentOptionsUniqaExAxa: PropTypes.object,
    displayDisclaimer: PropTypes.bool,
};

ContractOwnerPaymentsForm.defaultProps = {
    canEditAmount: true,
    showFrequency: true,
    showPaymentType: true,
    isUniqaContract: false,
    hasMonthlyFrequency: true,
    amountLabel: null,
    displayDisclaimer: false,
    paymentOptionsUniqa: PAYMENT_CONTRIBUTIONS_OPTIONS_UNIQA,
    paymentOptionsUniqaExAxa: PAYMENT_CONTRIBUTIONS_OPTIONS_EX_AXA,
};

export const createInPageContainer = (formName, formTitleKey) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        const { idObject } = useParams();
        const contract = userContracts.useGetContractById(idObject);
        return (
            <InPageFormLayout
                formName={formName}
                formTitle={t(formTitleKey, { contractNumber: contract.contractNumber })}
                changeSuccessTextType={ChangeSuccessTextType.CHANGE_SUBMITTED_FOR_PROCESSING}
                showFeedbackSurvey
            >
                <ContractOwnerPaymentsForm {...props} />
            </InPageFormLayout>
        );
    });
