import React, { FC } from "react";
import { Outlet } from "react-router-dom";

import homeImage from "ui-library/assets/images/domcek-pozadie.svg";
import businessImage from "ui-library/assets/images/business.svg";
import { Container } from "ui-library/atoms";
import { SubPageWrapper } from "ui-library/layouts";
import { Sidemenu } from "ui-library/modules";

import i18n from "core/i18n";
import userContracts from "core/userContracts";

import { ProductGroup, Roles } from "enums";
import { ContractHeader, ProductDetailPageLayout } from "containers/contract";
import { PageError } from "containers/error";
import { AppSidemenuLink } from "containers/link";
import { PageBoundary } from "containers/PageBoundary";
import { Tabs } from "routeConstants";

export const DetailHomePage: FC = () => {
    const { t } = i18n.useTranslation();
    const policy = userContracts.useGetContractByUrlParam();
    const hasDetail = policy.hasContractDetail;
    const { isFutureContract } = userContracts.useGetContractDerivedData(policy);

    const lobData = policy?.homeAddress && {
        label: t("insurance.contractedObject"),
        value: `${policy?.homeAddress || ""}`,
    };

    return (
        <ProductDetailPageLayout>
            <Container>
                <ContractHeader
                    backgroundImage={policy.idEnuProductGroup === ProductGroup.BUSINESS ? businessImage : homeImage}
                    policy={policy}
                    lobData={lobData}
                />

                <PageBoundary>
                    {hasDetail ? (
                        <SubPageWrapper>
                            <Sidemenu>
                                <AppSidemenuLink tab={Tabs.OVERVIEW} title={t("menu.tab.overview")} enabledFor={[Roles.AUTHENTICATED]} />
                                <AppSidemenuLink
                                    tab={Tabs.HOLDER}
                                    title={t("menu.tab.holder.insured")}
                                    enabledFor={[Roles.AUTHENTICATED]}
                                />
                                <AppSidemenuLink tab={Tabs.DETAIL} title={t("menu.tab.detail")} enabledFor={[Roles.AUTHENTICATED]} />
                                <AppSidemenuLink tab={Tabs.CHANGES} title={t("menu.tab.changes")} />
                                <AppSidemenuLink
                                    tab={Tabs.FINANCIAL_OPERATIONS}
                                    title={t("menu.tab.insuranceAmountAndPayments")}
                                    enabledFor={[Roles.AUTHENTICATED]}
                                />
                                {!isFutureContract && (
                                    <AppSidemenuLink tab={Tabs.CLAIMS} title={t("menu.tab.claims")} enabledFor={[Roles.AUTHENTICATED]} />
                                )}
                                <AppSidemenuLink tab={Tabs.DOCUMENTS} title={t("menu.tab.documents")} enabledFor={[Roles.AUTHENTICATED]} />
                                <AppSidemenuLink tab={Tabs.AGENT} title={t("menu.tab.agent")} enabledFor={[Roles.AUTHENTICATED]} />
                            </Sidemenu>

                            <Outlet />
                        </SubPageWrapper>
                    ) : (
                        <PageError
                            title={"error.contractDetailPermanentlyUnavailable.title"}
                            text={"error.contractDetailPermanentlyUnavailable.text"}
                        />
                    )}
                </PageBoundary>
            </Container>
        </ProductDetailPageLayout>
    );
};

export default DetailHomePage;
