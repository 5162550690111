import { useEffect } from "react";
import i18n from "core/i18n";
import { sentry } from "core/util";
import { GOOGLE_MAPS_API_KEY, RECAPTCHA_PUBLIC_KEY } from "app/constants";
import maps from "core/util/maps";

function loadGooglePlaces() {
    const places = document.createElement("script");
    places.type = "text/javascript";
    places.async = true;
    places.defer = true;
    places.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&lang=${i18n.getLanguageCode()}`;
    places.onload = () => maps.initPlaces();
    document.body.appendChild(places);
}

const loadGoogleRecaptcha = () =>
    new Promise((resolve, reject) => {
        const recaptcha = document.createElement("script");
        recaptcha.type = "text/javascript";
        recaptcha.async = true;
        recaptcha.onload = () => resolve();
        recaptcha.onerror = () => reject(new Error("Error loading Google Recaptcha init script."));
        recaptcha.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_PUBLIC_KEY}&lang=${i18n.getLanguageCode()}`;
        document.body.appendChild(recaptcha);
    }).catch((e) => sentry.captureException(e));

export const ScriptLoader = () => {
    useEffect(() => {
        loadGooglePlaces();
    }, []);
    useEffect(() => {
        loadGoogleRecaptcha();
    }, []);
    return null;
};
