import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Option, Select } from "ui-library/atoms";

import router from "core/router";
import { Pages, Params, Tabs } from "routeConstants";
import i18n from "core/i18n";
import { dateTimeToLocalFullDate } from "core/formatters";

import { getContractsByCategoryId, getIsAnyContractWithOwnNameByCategoryId, getSelectedClaimCategoryId } from "../selectors";
import { ContractOption } from "./ContractOption";

export const ContractSelect = ({ claimReportCategoryId }) => {
    const { t } = i18n.useTranslation();
    const contractsInCategory = useSelector(getContractsByCategoryId(claimReportCategoryId));
    const claimReportCategory = useSelector(getSelectedClaimCategoryId);
    const dispatch = useDispatch();
    const { contractId: selectedContractId } = useSelector(router.getCurrentQuery);
    const isAnyContractWithOwnName = useSelector(getIsAnyContractWithOwnNameByCategoryId(claimReportCategoryId));

    const doTransition = (idObject) =>
        router.navigate(
            Pages.CLAIMS,
            Tabs.REPORT_CLAIM,
            {},
            { [Params.PAYMENT_CONTRACT_ID]: idObject, [Params.CLAIM_REPORT_CATEGORY]: claimReportCategoryId },
            false,
        );

    return (
        <Select
            value={selectedContractId || ""}
            onChange={(event) => dispatch(doTransition(event.target.value))}
            placeholder={t("claim.contractSelectPlaceholder")}
            label={t("common.contract")}
            id="selectOther"
            fullWidth
        >
            {contractsInCategory &&
                contractsInCategory.map((contract) => (
                    <Option value={contract.get("value")} key={`${contract.get("value")}`}>
                        <ContractOption
                            leftText={contract.get("productCRM")}
                            rightText={t("page.reportClaim.dropdownContractInfo.contractNumberAndBeginDate", {
                                contractNumber: contract.get("contractNumber"),
                                beginDate: dateTimeToLocalFullDate(contract.get("beginDate")),
                            })}
                            noMiddleText={true}
                        />
                    </Option>
                ))}
        </Select>
    );
};
