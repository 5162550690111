import React, { FC } from "react";
import { RatingProps as MUIRatingProps } from "@mui/material";
import { StyledRating } from "ui-library/Styled";
import { Box, Flexbox } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";
import i18n from "core/i18n";

export interface RatingProps extends MUIRatingProps {
    reference?: React.ForwardedRef<any>;
}

export const Rating: FC<RatingProps> = ({ reference, ...props }) => {
    const { t } = i18n.useTranslation();
    return (
        <Box sx={{ display: "inline-block" }} p={1} bgcolor={"#ffffff"} border={"2px solid #DEDEE1"}>
            <StyledRating {...props} ref={reference} size="large" />

            <Flexbox alignItems={"flex-start"} gap={1} justifyContent={"space-between"}>
                <Typography variant={"caption"} color={"#CED4DA"} fontSize={10}>
                    {t("survey.stars.min")}
                </Typography>
                <Typography variant={"caption"} color={"#CED4DA"} fontSize={10}>
                    {t("survey.stars.max")}
                </Typography>
            </Flexbox>
        </Box>
    );
};
