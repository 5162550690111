import { Block, Button, Icon, Loading, Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import { DataTable } from "ui-library/modules";
import i18n from "core/i18n";
import useStepDocuments from "../hooks/useStepDocuments";
import { DocumentLink } from "containers/DocumentLink";
import { SignatureType } from "../types/VehicleSubjectChangeFormValues";
import { useState } from "react";
import FileUploadModal from "./FileUploadModal";
import { useHasRole } from "core/hooks";
import { Roles } from "enums";

const DOCUMENT_TO_SIGN = "VehicleDataOrSubjectChangeRequest";

export default function StepDocuments({ nextStep }: { nextStep: () => void }) {
    const { t } = i18n.useTranslation();
    const {
        idChange,
        signatureType,
        documents,
        rows,
        uploadedFile,
        href,
        signDocument,
        submit,
        getIsSubmitButtonDisabled,
        isDocumentsFetching,
        isSubmitting,
        isSigning,
        isDocumentSigned,
        emailInfoText,
    } = useStepDocuments(nextStep);

    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);

    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

    const baseColumns = [
        {
            field: "localName",
            headerName: `${t("documents.documentName")}`,
            flex: 2.5,
            sortable: false,
            renderCell: (params) => {
                if (!isAuthenticated && params?.row?.documentType !== DOCUMENT_TO_SIGN) {
                    return <DocumentLink fileName={params.value} fileUrl={href(idChange, params.row.id)} />;
                }
                return t("change.signature.signedRequest");
            },
        },
        {
            field: "issueDate",
            headerName: `${t("vehicle.change.subject.documents.issueDate")}`,
            flex: 1,
            sortable: false,
            renderCell: () => new Date().toLocaleDateString(),
        },
    ];

    const physicalSignatureColumns = [
        // {
        //     field: "uploadDate",
        //     headerName: `${t("vehicle.change.subject.documents.uploadDate")}`,
        //     flex: 1,
        //     sortable: false,
        //     renderCell: () => (uploadedFile ? new Date().toLocaleDateString() : ""),
        // },
        {
            field: "attachmentUpload",
            headerName: `${t("vehicle.change.subject.documents.attachmentUpload")}`,
            flex: 1.5,
            sortable: false,
            renderCell: () => {
                return (
                    // TODO: Replace with icon button
                    <Button variant="outlined" color="blue" onClick={() => setIsUploadModalOpen(true)}>
                        {t("common.uploadFile")}
                    </Button>
                );
            },
        },
        {
            field: "status",
            headerName: `${t("vehicle.change.subject.documents.attachmentStatus")}`,
            flex: 1,
            sortable: false,
            renderCell: () => uploadedFile && <Icon size={16} color={"green"} icon={"circle-check"} />,
        },
    ];

    const electronicSignatureColumns = [
        // {
        //     field: "signatureDate",
        //     headerName: `${t("vehicle.change.subject.documents.signatureDate")}`,
        //     flex: 1,
        //     sortable: false,
        //     renderCell: () => "",
        // },
        {
            field: "status",
            headerName: `${t("vehicle.change.subject.documents.signatureStatus")}`,
            flex: 1,
            sortable: false,
            renderCell: () => {
                return isDocumentSigned ? (
                    <Icon size={16} color={"green"} icon={"circle-check"} />
                ) : (
                    <Button variant="outlined" color="blue" onClick={signDocument}>
                        {isSigning ? <Loading loading={true} /> : t("vehicle.change.subject.documents.sign")}
                    </Button>
                );
            },
        },
    ];

    let columns = [...baseColumns];

    switch (signatureType) {
        case SignatureType.PHYSICAL:
            columns = [...baseColumns, ...physicalSignatureColumns];
            break;
        case SignatureType.ELECTRONIC:
            columns = [...baseColumns, ...electronicSignatureColumns];
            break;
        default:
            break;
    }

    const documentsToDisplay = documents.filter((document) => isAuthenticated || document.documentType !== DOCUMENT_TO_SIGN);

    return (
        <>
            <Box mb={8}>
                <Loading loading={isDocumentsFetching}>
                    {documentsToDisplay && documentsToDisplay.length > 0 && (
                        <>
                            <Box fullWidth textAlign={"center"} mb={2}>
                                <Typography variant="h3">{t("vehicle.change.subject.documents.attachments")}</Typography>
                            </Box>
                            <Box mb={6}>
                                <Block p={4} variant="white">
                                    {documentsToDisplay.map((document) => {
                                        if (document.id) {
                                            return (
                                                <Box key={document.id}>
                                                    <DocumentLink
                                                        fileName={document.label ?? document.documentType}
                                                        fileUrl={href(idChange, document.id.toString())}
                                                    />
                                                </Box>
                                            );
                                        }
                                    })}
                                </Block>
                            </Box>
                        </>
                    )}

                    <Box fullWidth textAlign={"center"} mb={2}>
                        <Typography variant="h3">{t("vehicle.change.subject.documents.signatures")}</Typography>
                    </Box>
                    <Box mb={6}>
                        <DataTable
                            disableColumnReorder
                            getRowId={(row) => row.id}
                            pageSize={10}
                            rowsPerPageOptions={[5, 10, 15, 20]}
                            rows={rows}
                            columns={columns}
                            hideFooter
                        />
                    </Box>

                    <Box mb={6}>
                        <Typography variant="body" markDown={emailInfoText} />
                    </Box>

                    <Box fullWidth textAlign={"center"}>
                        <Button variant="contained" color="blue" onClick={submit} disabled={getIsSubmitButtonDisabled()}>
                            {isSubmitting ? <Loading loading={true} /> : t("common.save")}
                        </Button>
                    </Box>
                </Loading>
            </Box>

            <FileUploadModal open={isUploadModalOpen} onClose={() => setIsUploadModalOpen(false)} />
        </>
    );
}
