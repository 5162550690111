import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { FormBlock, Step, Stepper } from "ui-library/modules";
import { Box, Flexbox, Section } from "ui-library/layouts";
import { Button, Container, Loading } from "ui-library/atoms";
import useBreakpoints from "ui-library/theme/breakpoints";

import fetching from "core/fetching";
import i18n from "core/i18n";
import router from "core/router";
import errorHandling, { OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS } from "core/errorHandling";
import { form, FormWideDisabledProvider } from "core/form";
import { FullPageOtpFormLayout } from "containers/fullPageForm";

import { FeeNotPaidComponent } from "./containers";
import { ProfileChangeComponent } from "./containers/ProfileChangeComponent";

import { BackToPortfolioTabButton } from "./containers/BackToPortfolioTabButton";
import { CannotChangeProfileComponent } from "./containers/CannotChangeProfileComponent";

const InvestmentProfileForm = ({
    investmentProfileFromDataSelector,
    availableProfilesSelector,
    availableFundsProfileChangeOptionsSelector,
    financialStatementEntityConst,
    feePaidSelector,
    isProductTempoCzSelector,
    hasActiveIndividualProfileSelector,
    contractNumberSelector,
    formName,
    canSubmit,
    handleSubmit,
    submitting,
    OtpModule,
}) => {
    const { t } = i18n.useTranslation();
    const contractNumber = useSelector(contractNumberSelector);
    const availableProfiles = useSelector(availableProfilesSelector);
    const isFeePaid = useSelector(feePaidSelector);

    const amount = useSelector(investmentProfileFromDataSelector);
    const requiredFee = amount.get("requiredFee");
    const paidFee = amount.get("paidFee");

    const isOtpVisibleSelector = OtpModule.createIsVisibleSelector(formName);
    const isOtpVisible = useSelector(isOtpVisibleSelector);

    const otpWrongNumberErrorExists = useSelector(errorHandling.isServiceInErrorState(OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS));

    const dispatch = useDispatch();
    const onOtpBack = () => dispatch(OtpModule.clear(formName));
    const onBack = () => dispatch(router.goBack());
    const isMDBreak = useBreakpoints("md");
    const fetchingFeeValue = useSelector(fetching.isFetching(fetching.FETCHING_PROFILE_CHANGE_FEE, true));
    const fetchingFinancialStatement = useSelector(fetching.isFetching(financialStatementEntityConst, true));
    const hasActiveIndividualProfile = useSelector(hasActiveIndividualProfileSelector);
    const showProfileChangeComponent = isFeePaid && !hasActiveIndividualProfile;
    const showCannotChangeProfileComponent = hasActiveIndividualProfile;
    const showFeeNotPaidComponent = !hasActiveIndividualProfile && !isFeePaid;
    return (
        <>
            <Container maxWidth="md">
                <Section>
                    <Stepper displayAsCircle={!isMDBreak} activeStep={isOtpVisible ? 1 : 0}>
                        <Step label={"Krok 1"} />
                        <Step label={"Krok 2"} />
                    </Stepper>
                </Section>
                <Container maxWidth="md">
                    <FormBlock>
                        <Loading loading={submitting || fetchingFinancialStatement}>
                            <FormWideDisabledProvider value={isOtpVisible}>
                                {!isOtpVisible && (
                                    <Box>
                                        {showProfileChangeComponent && (
                                            <ProfileChangeComponent
                                                formName={formName}
                                                contractNumber={contractNumber}
                                                availableProfiles={availableProfiles}
                                                availableFundsProfileChangeOptionsSelector={availableFundsProfileChangeOptionsSelector}
                                                isProductTempoCzSelector={isProductTempoCzSelector}
                                            />
                                        )}
                                        {showCannotChangeProfileComponent && <CannotChangeProfileComponent />}
                                        {showFeeNotPaidComponent && <FeeNotPaidComponent requiredFee={requiredFee} paidFee={paidFee} />}
                                    </Box>
                                )}
                            </FormWideDisabledProvider>
                            {isOtpVisible && (
                                <OtpModule.Container
                                    formName={formName}
                                    stepNumber={2}
                                    // ErrorComponent={OtpWrongNumberRegular}
                                />
                            )}
                        </Loading>
                        <Flexbox gap={2} justifyContent="space-between">
                            <Button
                                onClick={isOtpVisible && !otpWrongNumberErrorExists ? onOtpBack : onBack}
                                variant="outlined"
                                color="blue"
                            >
                                {isOtpVisible && !otpWrongNumberErrorExists ? t("form.otp.backToFirstStep") : t("common.back")}
                            </Button>
                            {isFeePaid && (
                                <Button
                                    onClick={handleSubmit}
                                    variant="contained"
                                    color="blue"
                                    disabled={!canSubmit || otpWrongNumberErrorExists || fetchingFeeValue}
                                >
                                    {t("common.send")}
                                </Button>
                            )}
                        </Flexbox>
                    </FormBlock>
                </Container>
            </Container>
        </>
    );
};

InvestmentProfileForm.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    OtpModule: PropTypes.object.isRequired,
    formName: PropTypes.string.isRequired,
    contractNumberSelector: PropTypes.func.isRequired,
    investmentProfileFromDataSelector: PropTypes.func.isRequired,
    availableFundsProfileChangeOptionsSelector: PropTypes.func.isRequired,
    financialStatementEntityConst: PropTypes.string.isRequired,
    availableProfilesSelector: PropTypes.func.isRequired,
    feePaidSelector: PropTypes.func.isRequired,
};

export const createContainer = (
    formName,
    contractNumberSelector,
    investmentProfileFromDataSelector,
    availableProfilesSelector,
    availableFundsProfileChangeOptionsSelector,
    financialStatementEntityConst,
    feePaidSelector,
    isProductTempoCzSelector,
    hasActiveIndividualProfileSelector,
    idObjectSelector,
    OtpModule,
) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        const idObject = useSelector(idObjectSelector);
        return (
            <FullPageOtpFormLayout
                formName={formName}
                formTitle={t("form.investmentProfile")}
                BackButton={<BackToPortfolioTabButton idObject={idObject} />}
                showFeedbackSurvey
            >
                <InvestmentProfileForm
                    formName={formName}
                    contractNumberSelector={contractNumberSelector}
                    investmentProfileFromDataSelector={investmentProfileFromDataSelector}
                    availableProfilesSelector={availableProfilesSelector}
                    availableFundsProfileChangeOptionsSelector={availableFundsProfileChangeOptionsSelector}
                    financialStatementEntityConst={financialStatementEntityConst}
                    feePaidSelector={feePaidSelector}
                    isProductTempoCzSelector={isProductTempoCzSelector}
                    hasActiveIndividualProfileSelector={hasActiveIndividualProfileSelector}
                    OtpModule={OtpModule}
                    {...props}
                />
            </FullPageOtpFormLayout>
        );
    });
