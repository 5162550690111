import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Section } from "ui-library/layouts";
import { Container, Typography } from "ui-library/atoms";
import { Step, Stepper } from "ui-library/modules";
import useBreakpoints from "ui-library/theme/breakpoints";

import router from "core/router";
import { form, FormErrorBox, getFormFieldValue } from "core/form";
import errorHandling, { OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS } from "core/errorHandling";
import i18n from "core/i18n";

import { CANCEL_FIELD_ID } from "./constants";
import { InnerContainerRegular } from "./containers";
import { BackToFinancialStatementButton } from "../fullPage/pensionFundsChange/components";
import { FullPageOtpFormLayout } from "containers/fullPageForm";

const MutualFundsSellSwitchRegularForm = ({
    handleSubmit,
    submitting,
    OtpModule,
    formName,
    canSubmit,
    getSourceFundOptions,
    getLocalizedFundSellMeansRegular,
    isGenNextSelector,
    getSellScopeOptions,
    getShouldCheckIbanCountry,
}) => {
    const { t } = i18n.useTranslation();
    const isOtpVisibleSelector = OtpModule.createIsVisibleSelector(formName);
    const isOtpVisible = useSelector(isOtpVisibleSelector);

    const otpWrongNumberErrorExists = useSelector(errorHandling.isServiceInErrorState(OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS));
    const isCancellingSelector = getFormFieldValue(formName, CANCEL_FIELD_ID);
    const isCancelling = !!useSelector(isCancellingSelector);

    const dispatch = useDispatch();
    const onOtpBack = () => dispatch(OtpModule.clear(formName));
    const onBack = () => dispatch(router.goBack());

    const isMDBreak = useBreakpoints("md");

    const InnerContainerRegularProps = {
        handleSubmit,
        submitting,
        isOtpVisible,
        otpWrongNumberErrorExists,
        onOtpBack,
        isCancelling,
        OtpModule,
        formName,
        canSubmit,
        onBack,
        getSourceFundOptions,
        getLocalizedFundSellMeansRegular,
        isGenNextSelector,
        getSellScopeOptions,
        getShouldCheckIbanCountry,
    };
    return (
        <Container maxWidth="md">
            <Section>
                <Typography variant="lead">{t("investments.sellSwitch.descriptionRegular")}</Typography>
            </Section>

            <Section>
                <Stepper displayAsCircle={!isMDBreak} activeStep={isOtpVisible ? 1 : 0}>
                    <Step label={"Krok 1"} />
                    <Step label={"Krok 2"} />
                </Stepper>
            </Section>
            <Container maxWidth="md">
                <FormErrorBox form={formName} />

                <InnerContainerRegular {...InnerContainerRegularProps} />
            </Container>
        </Container>
    );
};

MutualFundsSellSwitchRegularForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    OtpModule: PropTypes.object.isRequired,
    formName: PropTypes.string.isRequired,
    getSourceFundOptions: PropTypes.func.isRequired,
    isGenNextSelector: PropTypes.func.isRequired,
    getLocalizedFundSellMeansRegular: PropTypes.func.isRequired,
    getSellScopeOptions: PropTypes.func.isRequired,
    getShouldCheckIbanCountry: PropTypes.func.isRequired,
};

export default (
    formName,
    OtpModule,
    getSourceFundOptions,
    getLocalizedFundSellMeansRegular,
    isGenNextSelector,
    getSellScopeOptions,
    idObjectSelector,
    getShouldCheckIbanCountry,
    slowServices,
) =>
    form(
        formName,
        {},
    )((props) => {
        const { t } = i18n.useTranslation();
        const idObject = useSelector(idObjectSelector);
        return (
            <FullPageOtpFormLayout
                formName={formName}
                formTitle={t("investments.sellSwitch.formTitle")}
                BackButton={<BackToFinancialStatementButton idObject={idObject} />}
                showFeedbackSurvey
                surveyFormJourney={"Maturity"}
            >
                <MutualFundsSellSwitchRegularForm
                    {...props}
                    formName={formName}
                    OtpModule={OtpModule}
                    getSourceFundOptions={getSourceFundOptions}
                    getLocalizedFundSellMeansRegular={getLocalizedFundSellMeansRegular}
                    isGenNextSelector={isGenNextSelector}
                    getSellScopeOptions={getSellScopeOptions}
                    getShouldCheckIbanCountry={getShouldCheckIbanCountry}
                    slowServices={slowServices}
                />
            </FullPageOtpFormLayout>
        );
    });
