import { API_PREFIX } from "app/constants";
import to from "core/util/fetch/awaitToJs";
import superagent from "superagent";
import { SubmitSurveyRequest } from "types";

const submit = async (body: SubmitSurveyRequest): Promise<[Error | null, any]> => {
    return await to(superagent.post(`${API_PREFIX}/survey`).send(body).set("Content-Type", "application/json").accept("json"));
};

const surveyApi = {
    submit,
};

export default surveyApi;
