import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { ContentBox } from "ui-library/layouts";
import { Button, Loading, Typography } from "ui-library/atoms";
import { Field, form, FormWideDisabledProvider, getFormFieldValue, required, SegmentedControl, SingleSelect } from "core/form";
import i18n from "core/i18n";
import { getLocalizedStatementFrequencyOptions } from "core/localized";

import { RECEIVE_STATEMENT_FIELD, STATEMENT_PERIODICITY_FIELD } from "./constants";
import { ChangeSuccessTextType } from "types";
import { InPageFormButtonsLayout, InPageFormLayout } from "containers/inPageForm";
import { useParams } from "react-router-dom";
import userContracts from "core/userContracts";

const PensionStatementSettingsForm = ({ formName, canSubmit, handleSubmit, submitting, isElComSet }) => {
    const { t } = i18n.useTranslation();
    const frequencyOptions = useSelector(getLocalizedStatementFrequencyOptions);
    const receiveStatements = useSelector(getFormFieldValue(formName, RECEIVE_STATEMENT_FIELD));

    if (!isElComSet) {
        return (
            <ContentBox fullWidth textAlign={"center"}>
                <Typography markDown={t("general.clientStatement.missingElectronicCommunicationForChange")} />
            </ContentBox>
        );
    }

    return (
        <>
            <FormWideDisabledProvider value={submitting}>
                <ContentBox fullWidth>
                    <Field name={RECEIVE_STATEMENT_FIELD} component={SegmentedControl} label={t("form.receiveStatement")} />
                </ContentBox>

                {receiveStatements && (
                    <ContentBox fullWidth>
                        <Field
                            name={STATEMENT_PERIODICITY_FIELD}
                            component={SingleSelect}
                            label={t("form.statementPeriodicity")}
                            options={frequencyOptions}
                            validateStatic={[required]}
                            placeholder={t("common.choose")}
                        />
                    </ContentBox>
                )}
            </FormWideDisabledProvider>

            <InPageFormButtonsLayout
                rightButton={
                    <Button color="blue" variant="contained" onClick={handleSubmit} disabled={!canSubmit || submitting}>
                        {submitting ? <Loading loading /> : t("common.submitChange")}
                    </Button>
                }
            />
        </>
    );
};

PensionStatementSettingsForm.propTypes = {
    formName: PropTypes.string.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    isElComSet: PropTypes.bool,
};

PensionStatementSettingsForm.defaultProps = {
    isElComSet: false,
};

export const createInPageContainer = (formName, getIsElComSet) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        const { idObject } = useParams();
        const contract = userContracts.useGetContractById(idObject);
        const isElComSet = useSelector(getIsElComSet);

        return (
            <InPageFormLayout
                formName={formName}
                formTitle={t("change.title.STATEMENTS_SETTINGS", { contractNumber: contract.contractNumber })}
                changeSuccessTextType={ChangeSuccessTextType.CHANGE_SUBMITTED_FOR_PROCESSING}
                showFeedbackSurvey
            >
                <PensionStatementSettingsForm formName={formName} isElComSet={isElComSet} {...props} />
            </InPageFormLayout>
        );
    });
