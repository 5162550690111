import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ContentBox, GridItem, GridLayout } from "ui-library/layouts";
import { Button, Loading } from "ui-library/atoms";

import i18n from "core/i18n";
import localized from "core/localized";
import pageContext from "core/pageContext";
import userContracts from "core/userContracts";
import {
    Field,
    form,
    FormWideDisabledProvider,
    getAmountNormalizerBySite,
    getFormFieldValue,
    getValidateAmountBySite,
    greaterThanZero,
    InputText,
    lettersNumbersBasicInterpunctionCharacters,
    lettersNumbersSpecialCharactersExtended,
    max10Characters,
    max50Characters,
    maxLengthCharacters,
    maxValue,
    numberCharacters,
    required,
    SegmentedControl,
    SingleSelect,
} from "core/form";
import { localCurrency } from "core/formatters";
import { InPageFormButtonsLayout, InPageFormLayout } from "containers/inPageForm";
import { ChangeSuccessTextType, FeatureFlag } from "types";

import {
    CONTRIBUTION_AMOUNT,
    CONTRIBUTION_AMOUNT_TYPE_IS_PERCENTAGE,
    CONTRIBUTION_PERCENTAGE,
    EMPLOYER_NAME,
    ENABLE_EMPLOYER_CONTRIBUTION,
    FREQUENCY_OF_PAYMENT,
    IDENTIFICATION_NUMBER,
} from "./constants";

const EmployerPaymentsForm = ({ formName, canSubmit, handleSubmit, submitting }) => {
    const { t } = i18n.useTranslation();
    const { siteId, currencyCode } = pageContext.useGetPageLocalization();
    const isSpecialCharactersEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.EDIT_COMPANY_NAME_SPECIAL_CHARACTERS);

    const isEmployerPaymentsEnabled = useSelector(getFormFieldValue(formName, ENABLE_EMPLOYER_CONTRIBUTION));
    const isContributionAmountTypePercentage = useSelector(getFormFieldValue(formName, CONTRIBUTION_AMOUNT_TYPE_IS_PERCENTAGE));

    const frequencyOptions = useSelector(localized.getLocalizedFrequencyOptions);

    return (
        <>
            <FormWideDisabledProvider value={submitting}>
                <ContentBox fullWidth>
                    <Field name={ENABLE_EMPLOYER_CONTRIBUTION} component={SegmentedControl} label={t("form.employerPayments")} />
                </ContentBox>

                {isEmployerPaymentsEnabled && (
                    <>
                        <ContentBox fullWidth>
                            <GridLayout defaultSpacing>
                                <GridItem xs={6}>
                                    <Field
                                        name={IDENTIFICATION_NUMBER}
                                        component={InputText}
                                        label={t("holder.identificationNumber")}
                                        // validated async in saga!
                                        normalize={[numberCharacters, max10Characters]}
                                        onEnter={handleSubmit}
                                    />
                                </GridItem>
                                <GridItem xs={6}>
                                    <Field
                                        name={EMPLOYER_NAME}
                                        component={InputText}
                                        label={t("holder.employerName")}
                                        validateStatic={[required]}
                                        normalize={[
                                            isSpecialCharactersEnabled
                                                ? lettersNumbersSpecialCharactersExtended
                                                : lettersNumbersBasicInterpunctionCharacters,
                                            max50Characters,
                                        ]}
                                        onEnter={handleSubmit}
                                    />
                                </GridItem>
                            </GridLayout>
                        </ContentBox>

                        <ContentBox fullWidth>
                            <Field
                                name={CONTRIBUTION_AMOUNT_TYPE_IS_PERCENTAGE}
                                component={SegmentedControl}
                                label={t("form.employerContribution.amountTypePercentage")}
                            />
                        </ContentBox>

                        <ContentBox fullWidth>
                            <GridLayout defaultSpacing>
                                {/* Percentage field*/}
                                {isContributionAmountTypePercentage && (
                                    <GridItem xs={6}>
                                        <Field
                                            name={CONTRIBUTION_PERCENTAGE}
                                            component={InputText}
                                            label={t("form.employerContribution.percentage")}
                                            sufix="%"
                                            validateStatic={[required, greaterThanZero, maxValue(100)]}
                                            normalize={[numberCharacters, maxLengthCharacters(2)]}
                                            onEnter={handleSubmit}
                                        />
                                    </GridItem>
                                )}

                                {/* Amount field */}
                                {!isContributionAmountTypePercentage && (
                                    <GridItem xs={6}>
                                        <Field
                                            name={CONTRIBUTION_AMOUNT}
                                            component={InputText}
                                            label={t("form.employerContribution.amount")}
                                            sufix={localCurrency(currencyCode)}
                                            validateStatic={getValidateAmountBySite(siteId)}
                                            normalize={[getAmountNormalizerBySite(siteId), max10Characters]}
                                            onEnter={handleSubmit}
                                        />
                                    </GridItem>
                                )}

                                <GridItem xs={6}>
                                    <Field
                                        name={FREQUENCY_OF_PAYMENT}
                                        component={SingleSelect}
                                        label={t("form.employerContribution.frequency")}
                                        options={frequencyOptions}
                                        validateStatic={[required]}
                                        placeholder={t("common.choose")}
                                    />
                                </GridItem>
                            </GridLayout>
                        </ContentBox>
                    </>
                )}
            </FormWideDisabledProvider>

            <InPageFormButtonsLayout
                rightButton={
                    <Button variant="contained" color="blue" onClick={handleSubmit} disabled={!canSubmit || submitting}>
                        {submitting ? <Loading loading /> : t("common.submitChange")}
                    </Button>
                }
            />
        </>
    );
};

EmployerPaymentsForm.propTypes = {
    formName: PropTypes.string.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

export const createInPageContainer = (formName) =>
    form(formName, { asyncValidationFields: [IDENTIFICATION_NUMBER] })((props) => {
        const { t } = i18n.useTranslation();
        const { idObject } = useParams();
        const contract = userContracts.useGetContractById(idObject);

        return (
            <InPageFormLayout
                formName={formName}
                formTitle={t("change.title.EMPLOYER_PAYMENTS", { contractNumber: contract.contractNumber })}
                changeSuccessTextType={ChangeSuccessTextType.CHANGE_SUBMITTED_FOR_PROCESSING}
                showFeedbackSurvey
            >
                <EmployerPaymentsForm formName={formName} {...props} />
            </InPageFormLayout>
        );
    });
