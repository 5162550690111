import { ContractPolicy } from "types";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

import { date as dateUtil } from "core/util";

import { ContractDerivedData, ContractsDerivedData } from "./constants";
import { createGetContractById, getContractList } from "./selectors";

import { ContractStatus } from "enums";

export const useGetContracts: () => ContractPolicy[] = () => {
    return useSelector(getContractList);
};

export const useGetActiveContracts: () => ContractPolicy[] = () => {
    const allContracts = useGetContracts();
    return allContracts.filter((contract) => contract.isActive);
};

export const useGetContractsDerivedData: () => ContractsDerivedData = () => {
    const activeContracts = useGetActiveContracts();

    const hasMoreThanOneActiveContract = activeContracts.length > 1;

    const hasAnyThisMonthMovements = activeContracts.filter((contract) => hasThisMonthMovements(contract)).length > 0;
    const hasAnyLastMonthMovements = activeContracts.filter((contract) => hasLastMountMovements(contract)).length > 0;
    const hasAnyFailedFetchingMovements = activeContracts.filter((contract) => hasFailedFetchingMovements(contract)).length > 0;

    return { hasMoreThanOneActiveContract, hasAnyThisMonthMovements, hasAnyLastMonthMovements, hasAnyFailedFetchingMovements };
};

export const useGetContractById: (idObject: string | number) => ContractPolicy = (idObject) => {
    return useSelector(createGetContractById(Number(idObject)));
};

export const useGetContractByUrlParam: () => ContractPolicy = () => {
    const { idObject } = useParams();
    if (!idObject) {
        return null;
    }
    return useGetContractById(idObject);
};

export const useGetContractByContractNumber: (contractNumber: string) => ContractPolicy = (contractNumber) => {
    const contractPolicies = useSelector(getContractList) || [];
    return contractPolicies
        .filter((policy) => policy.contractNumber === contractNumber)
        .slice(-1)
        .pop();
};

export const useGetContractDerivedData: (contract: ContractPolicy) => ContractDerivedData = (contract) => {
    const isFutureContract = contract.idEnuContractStatus === ContractStatus.ACCEPTED && !contract.isActive;
    const isInFutureOrTerminated = isFutureContract || contract.idEnuContractStatus === ContractStatus.TERMINATED;
    const showEndDate = ContractStatus.TERMINATED === contract.idEnuContractStatus;
    const isEndedNotThisOrLastYear = endedNotThisOrLastYear(contract);

    return { isFutureContract, isInFutureOrTerminated, showEndDate, isEndedNotThisOrLastYear };
};

//todo move to some util
const hasThisMonthMovements = (contract: ContractPolicy): boolean => {
    return contract?.financialOperations?.thisMonthMovements.size > 0;
};

const hasLastMountMovements = (contract: ContractPolicy): boolean => {
    return contract?.financialOperations?.lastMountMovements.size > 0;
};

const hasFailedFetchingMovements = (contract: ContractPolicy): boolean => {
    return contract?.financialOperations?.isFailed;
};

const endedNotThisOrLastYear = (contract: ContractPolicy): boolean => {
    if (contract.idEnuContractStatus !== ContractStatus.TERMINATED) {
        return false;
    }
    const endYear = dateUtil.getYearFromStringDateTime(contract.endDate);
    const currentYear = moment.utc().year();
    return currentYear - endYear > 1;
};
